<template>
  <div class="content_home">
    <div>
      <img class="logo_" alt="STRCOS Logo" src="../../assets/logo.png">
    </div>
    <div>
      <h2>厦门虎鲸梦想网络科技有限公司</h2>
      <h5>Xiamen Orca Dream Network Technology Co.,Ltd.</h5>
    </div>
  </div>
</template>

<script>

export default {
  name: 'home',
  data(){
    return {

    }
  },
  methods:{

  }
}
</script>

<style>
.content_home {
  padding-top: 20vh;
}
.logo_ {
  width: 319px;
  height: 179px;
}
</style>